@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: white;
}

.accordion-simple > .active {
  display: block;
}

.accordion__faq .inactive{
  display: none;
}

.accordion__faq > div{
  margin-bottom: 10px;
  padding: 0px 10px;
  cursor: pointer;
}

.accordion__faq-heading{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content-div {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 250px;
  width: 100%;
}

.content-div:hover {
  background-image: linear-gradient(
      to right,
      #41545C,
      #57757E  
  )!important;
}